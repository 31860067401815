<template>
    <div class="main analytics-page">
        <div class="main-width" v-if="isAuth">
            <h2> Аналитика по тарифу "{{getName(tariff)}}" </h2>

            <div>
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="pills-all-analytics-tab" data-toggle="pill" href="#pills-all-analytics" role="tab" aria-controls="pills-all-analytics" aria-selected="true">
                            {{ $t("analytics.all") }}
                        </a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pills-fav-profile-tab" data-toggle="pill" href="#pills-fav-profile" role="tab" aria-controls="pills-fav-profile" aria-selected="false">
                            {{ $t("analytics.favourites") }}
                        </a>
                    </li>
                </ul>

                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-all-analytics" role="tabpanel" aria-labelledby="pills-all-analytics-tab">

                    <div class="row no-gutters">
                        <div 
                            class="col-12 col-md-6 col-lg-4"
                            v-for="item in analytics"
                            :key="item.id"
                            >
                            <div class="service">
                                <div class="service__img">
                                    <img src="@/assets/img/icons/layer1.svg" alt="">
                                </div>
                                <div class="service__body" >
                                    <h2> {{getName(item)}} </h2>
                                    <p>{{getDescription(item)}}</p>
                                    <router-link  
                                        :to="{name: 'analytic', params: {id: item.id}}" 
                                    >{{$t("analytics.open")}}&nbsp;&nbsp;&#x279C;
                                    </router-link>
                                </div>
                                <i class="analytics-favourite far fa-star" :class="{activeStar: isFavourite(item.id) }" @click.prevent="toggleFavourite(item)"></i>
                            </div>
                        </div>
                    </div>

                    </div>
                    <div class="tab-pane fade" id="pills-fav-profile" role="tabpanel" aria-labelledby="pills-fav-profile-tab">

                        <div class="row no-gutters">
                            <div 
                                class="col-12 col-md-6 col-lg-4"
                                v-for="item in favoutires"
                                :key="item.analytics.id"
                            >
                                <div class="service">
                                    <div class="service__img">
                                        <img src="@/assets/img/icons/layer1.svg" alt="">
                                    </div>
                                    <div class="service__body" >
                                        <h2> {{getName(item.analytics)}} </h2>
                                        <p>{{getDescription(item.analytics)}}</p>
                                        <router-link 
                                            :to="{name: 'analytic', params: {id: item.analytics.id}}"
                                        >{{$t("analytics.open")}}&nbsp;&nbsp;&#x279C;</router-link>
                                    </div>
                                    <i class="analytics-favourite far fa-star" :class="{activeStar: isFavourite(item.analytics.id) }" @click.prevent="toggleFavourite(item.analytics)"></i>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <beat-loader 
                v-if="loading" 
                class="loader-center" 
                :color="'#553DB4'" 
                :loading="loading" 
                :size="20" 
                :sizeUnit="'px'" 
                />

        </div>
        <div class="main-width" v-else>
            <div class="alert alert-danger py-5" role="alert">
                <p class="text-center">{{$t("message.isNotAuth")}}</p>
            </div>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'

    export default {
        name: 'analytics',
        data: () => ({
            items: [],
            favourite: [],
            loading: false,
        }),
        mounted() {
            this.onShow();
            this.getFavourite();
        },
        methods: {
            onShow() {
                this.getItems();
            },
            getFavourite() {
                this.favourite = [];
                this.$store.dispatch("LOAD_ANALYTICS_FAVOURITE")
                    .then((s) => {
                        this.favourite = s.data;
                    });
            },
            toggleFavourite(item) {
                if (this.favouriteIds.includes(item.id)) {
                    this.deleteFav(item);
                }
                else {
                    this.saveFav(item);
                }
            },
            saveFav(analytic) {
                this.$store.dispatch("SAVE_ANALYTICS_FAVOURITE", {
                    userId: this.user.id,
                    analytics: analytic
                })
                .then(() => this.getFavourite());
            },
            deleteFav(item) {
                this.favourite.forEach(el => {
                    if (el.analytics.id === item.id) {
                        this.$store.dispatch("DELETE_ANALYTICS_FAVOURITE", el.id)
                        .then(() => this.getFavourite());
                    }
                });
            },
            isFavourite(id) {
                return this.favouriteIds.includes(id);
            },
            getName(i) {
                let name;
                switch(this.$i18n.locale) {
                    case 'kz':
                        name = i.nameKz;
                        break;
                    default:
                        name = i.nameRu;
                }
                return name;
            },
            getDescription(i) {
                let description;
                switch(this.$i18n.locale) {
                    case 'kz':
                        description = i.descriptionKz;
                        break;
                    default:
                        description = i.descriptionRu;
                }
                return description;
            },
            getItems() {
                this.loading = true;

                const params = {
                    entity: 'Analytics'
                };

                this.$store.dispatch('GET_ITEMS', params) 
                    .then((res) => {
                        this.items = res.data
                        this.loading  = false
                    })
                    ;
            },
        },
        computed: {
            ...mapGetters([
                'USER_TARIFF',
                'user_tarif_name',
                'user_tarif_id',
                'user',
                'isAuth'
            ]),
            tariff() {
                return this.USER_TARIFF;
            },
            analytics() {
                return this.items && this.items.filter( i => {
                    if ( i.analyticsTariffList
                            .filter( t => t.dtariffId.id === this.tariff.id).length > 0) {
                            return true;
                        }
                    })
                    .sort((a, b) => a.sort - b.sort)
                    ;
            },
            favoutires() {
                return this.favourite && this.favourite.filter( i => {
                    if ( i.analytics.analyticsTariffList
                            .filter( t => t.dtariffId.id === this.tariff.id).length > 0) {
                            return true;
                        }
                    });
            },
            favouriteIds() {
                return this.favourite && this.favourite.map(el => el.analytics.id);
            },
        },
        watch: {
            isAuth(val, oldVal) {
                if (val === true && oldVal === false){
                    this.onShow();
                }
            }
        }

    }
</script>

<style lang="scss" scoped>
h2 {
    margin-bottom: 40px;
}
.service {
    padding: 25px;
    min-height: 250px;
}
.service__body h2 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
    line-height: 1.3;
}

.service__img{
    padding: 10px;
    img {
        max-width: 35px;
    }
}

.service .analytics-favourite {
    position: absolute;
    right: 25px;
    bottom: 25px;
    cursor: pointer;
}


.analytics-page {
    min-height: 50vh;
}

</style>